import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Gerant, ChangeMotDePasse, Client, Fournisseur} from './general.model';
@Injectable({
  providedIn: 'root'
})
export class GerantService {
  private host: string = 'https://samastock.alwaysdata.net/anta/api';
  constructor(private http:HttpClient) { }
  ger : Gerant;
  up : ChangeMotDePasse;

  getAllGerant(){
   return this.http.get(this.host+"/user/getAllUser?token="+localStorage.getItem('token'));
  }
  public deleteGer(id){
    return this.http.delete(this.host+'/user/deleteById/'+id+"?token="+localStorage.getItem('token'));
  }

  public GetGerantById(id){
    return this.http.get(this.host+'/user/byid/'+id+"?token="+localStorage.getItem('token'));
  }

  public GetClientById(id){
    return this.http.get(this.host+"/client/byid/"+id+"?token="+localStorage.getItem('token'));
  }
  public GetFournisseurById(id){
    return this.http.get(this.host+"/fournisseur/byid/"+id+"?token="+localStorage.getItem('token'));
  }
  getAllGerantByMagasin(id){
    return this.http.get(this.host+'/user/bymagasin/'+id+'?token='+localStorage.getItem('token'));
   }

  insertGerant(ger:Gerant,idMagasin){
    return this.http.post(this.host+'/register?nom='+ger.nom+'&'+'prenom='+ger.prenom+'&'+'tel='+ger.tel+
    '&'+'login='+ger.login+'&'+'email='+ger.email+'&'+'password='+"passer"+'&'+'typeUser_id='+ger.typeUser_id+
    '&'+'magasin_id='+idMagasin+"&token="+localStorage.getItem('token'),Gerant);
  }

  updateGerant(ger:Gerant,id,idMagasin){
    return this.http.put(this.host+'/user/updateById/'+id+'?nom='+ger.nom+'&'+'prenom='+ger.prenom+'&'+'tel='+
    ger.tel+'&'+'login='+ger.login+'&'+'email='+ger.email+'&'+'typeUser_id='+ger.typeUser_id+'&'+'magasin_id='+
    idMagasin+"&token="+localStorage.getItem('token'),Gerant);

  }


  updateClient(client:Client,id){
    return this.http.put(this.host+'/client/updateById/'+id+'?nomClient='+client.nom+'&'+'prenomClient='+
    client.prenom+'&'+'adresseClient='+client.adresse+'&'+'telClient='+client.tel+"&typeClient_id=1&token="+
    localStorage.getItem('token'),Client);
  }
  updateFournisseur(fournisseur:Fournisseur,id){
    return this.http.put(this.host+'/fournisseur/updateById/'+id+'?nomClient='+fournisseur.nom+'&'+'prenomClient='
    +fournisseur.prenom+'&'+'adresseClient='+fournisseur.adresse+'&'+'telClient='+fournisseur.tel+"&typeClient_id=1&token="
    +localStorage.getItem('token'),Fournisseur);
  }


  getAllDette(id){
    return this.http.get(this.host+"/client/getAllClientWithDette/bymagasin/"+id+"?token="+localStorage.getItem('token'));

  }

  getAllDetteByClient(id){
    return this.http.get(this.host+"/client/getAllDetteByClient/"+id+"?token="+localStorage.getItem('token'));

  }

  getDetteByIdFacture(getDetteByIdFacture){
    return this.http.get(this.host+"/client/getDetteByIdFacture/"+getDetteByIdFacture+"?token="+localStorage.getItem('token'));
  }
  updatePasswordGerant(id,nmdp){

    return this.http.put(this.host+'/user/updatePasswordUser/'+id+'?password='+nmdp+"&token="+localStorage.getItem('token'),Gerant);

  }
  getAllTypeUser(){
    return this.http.get(this.host+"/typeuser/getAllTypeUser?token="+localStorage.getItem('token'));
   }

   getAllClient(){
    return this.http.get(this.host+'/client/getAllClient'+'?magasin_id='+localStorage.getItem('idmagasin')+'&token='+localStorage.getItem('token'));
   }
  getBestClient(){
    return this.http.get(this.host+'/client/getBestClient?magasin_id='+localStorage.getItem('idmagasin')+'&token='+localStorage.getItem('token'));
  }

  getAllFournisseur(){
    return this.http.get(this.host+'/fournisseur/getAllFournisseur'+'?token='+localStorage.getItem('token'));

  }

}
